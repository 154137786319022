<template>
 <div>
  <svg id="Laag_1" style="enable-background:new 0 0 183 183;" version="1.1" viewBox="0 0 183 183" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
   <defs>
    <linearGradient gradientTransform="matrix(113.93 0 0 101.27 150666.9844 13272.8018)" gradientUnits="userSpaceOnUse" id="icon-maps_00000170992301278666879320000017620670468642524046_" x1="-1321.2314" x2="-1321.9515" y1="-130.4249" y2="-129.7449">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <rect :fill="background" height="183" width="183"/>
   <path d="M91.5,40.9  c-13.8,0-24.9,11.1-24.9,24.9c0,0,0,0,0,0l0,0c0,11.1,16.3,31.4,22.5,38.8c1.1,1.3,3.1,1.5,4.4,0.4c0,0,0,0,0,0  c0.1-0.1,0.3-0.3,0.4-0.4c6.2-7.4,22.5-27.6,22.5-38.8C116.4,52,105.3,40.9,91.5,40.9z M91.5,74.1c-4.6,0-8.3-3.7-8.3-8.3  s3.7-8.3,8.3-8.3c4.6,0,8.3,3.7,8.3,8.3c0,0,0,0,0,0C99.8,70.4,96.1,74.1,91.5,74.1C91.5,74.1,91.5,74.1,91.5,74.1z M38.5,83.6  c-2.4,1-4,3.3-4,5.9V139c0,1.7,1.4,3.2,3.2,3.2h0c0.4,0,0.8-0.1,1.2-0.2l27.3-12.4V83.4c-1.6-2.9-3-6-4.1-9.2L38.5,83.6z M91.5,112  c-2.8,0-5.4-1.2-7.2-3.4c-3.9-4.6-8-9.8-11.8-15.2v36l38,12.6V93.5c-3.7,5.4-7.9,10.6-11.8,15.2C96.9,110.8,94.3,112,91.5,112z   M144.1,72.7l-27.3,12.4v57l27.7-11.1c2.4-1,4-3.3,4-5.9V75.7c0-1.7-1.4-3.2-3.1-3.2c0,0,0,0,0,0C144.9,72.5,144.5,72.6,144.1,72.7  L144.1,72.7z" id="icon-maps" style="fill:url(#icon-maps_00000170992301278666879320000017620670468642524046_);"/>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>